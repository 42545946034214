import React from "react"
import { Link, graphql } from "gatsby"
import {Grid, Card,CardMedia, CardActionArea, CardContent,CardActions, Typography, Button} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const Wiki = ({ data, location }) => {
  const classes = useStyles();
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Der Elektronik Blog" />
      <Grid container spacing={3} alignItems="stretch">
      {posts.map(({ node }) => {
        console.log(node)
        const title = node.frontmatter.title || node.fields.slug

        console.log(node.frontmatter)

        return (
          <Grid key={node.fields.slug} item xs={6} md={3}>
            <Card className={classes.root}>
            <Link style={{ boxShadow: `none`, textDecoration: 'none' }} to={node.fields.slug}>
              <CardActionArea>
                { node.frontmatter.coverImage && (
                  <CardMedia className={classes.media} image={node.frontmatter.coverImage.childImageSharp.fluid.src} />
                )}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                    {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {node.frontmatter.description || node.excerpt}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions >
                  
                    <Button size="small" color="primary">
                      Anzeigen
                    </Button>
                  
                </CardActions>
              </Link>
            </Card>
          </Grid>
        )
      })}
      </Grid>
    </Layout>
  )
}

export default Wiki

// use allBlogposts instead of  filter: {fileAbsolutePath: {regex: "/blog/"}}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC },  filter: {fileAbsolutePath: {regex: "/wiki/"}}) { 
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            description
            coverImage{ 
              publicURL
              childImageSharp{
                fluid (maxWidth:500, quality:50){
                  src,
                  srcSet
                  aspectRatio
                  sizes
                  base64
                }
              }
            }
          }
        }
      }
    }
  }
`


/*
 query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            description
            coverImage{
                childImageSharp{
                  fluid (maxWidth:500, quality:50){
                    src,
                    srcSet
                    aspectRatio
                    sizes
                    base64
                  }
                }
                publicURL
              }
          }
        }
      }
    }
  }
*/